import React, { useState } from 'react';

import { Box, Button, COLORS, Text } from '@clutter/clean';

import tracking from '@portal/images/illustrations/location_truck_tracking.svg';
import moverPackingBoxes from '@portal/images/illustrations/mover_packing_boxes.svg';
import moversWithTv from '@portal/images/illustrations/movers_with_tv_small.svg';
import truck from '@portal/images/illustrations/moving_truck_small.svg';
import tape from '@portal/images/illustrations/tape_multi_small.svg';
import stackedBoxes from '@portal/images/illustrations/two_stacked_yellow_boxes_small.svg';
import { useHistory, useParams } from 'react-router-dom';
import {
  AppointmentHubDocument,
  DoorToDoor__BundleEnum,
  Status,
  usePickupServicePackageUpdateMutation,
  usePickupServicePackageUpdateOrderQuery,
} from '@portal/schema';
import { ordersURL } from '@portal/config/routes';
import { Card } from './card';

export const PickupServicePackageUpdate: React.FC = () => {
  const { orderID } = useParams<{ orderID: string }>();

  const [selectedServicePackage, setSelectedServicePackage] = useState<DoorToDoor__BundleEnum | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const history = useHistory();

  const [update, { loading: saving }] = usePickupServicePackageUpdateMutation({
    refetchQueries: [{ query: AppointmentHubDocument, variables: { orderID } }],
  });

  const { data: appointmentData } = usePickupServicePackageUpdateOrderQuery({
    variables: { orderID },
  });

  const order = appointmentData?.order;

  if (!order) {
    return null;
  }

  const currentServicePackage = order.tags.find((tag) => tag === 'Packing Help')
    ? DoorToDoor__BundleEnum.PickupAndPacking
    : DoorToDoor__BundleEnum.Basic;

  const options = order.pickupServicePackageOptions;
  const basicBundle = options.find((option) => option.kind === DoorToDoor__BundleEnum.Basic);
  const pickupAndPackingBundle = options.find((option) => option.kind === DoorToDoor__BundleEnum.PickupAndPacking);

  if (!basicBundle || !pickupAndPackingBundle) return null;

  const saveSelection = async () => {
    if (saving) {
      return;
    }

    if (!selectedServicePackage || selectedServicePackage === currentServicePackage) {
      history.push(ordersURL());
      return;
    }

    const response = await update({
      variables: {
        input: {
          orderID: order.id,
          servicePackage: selectedServicePackage!,
        },
      },
    });

    const data = response.data?.pickupServicePackageUpdate;
    if (data?.status === Status.Ok) {
      history.push(ordersURL());
    } else {
      setError(data?.error ?? 'Sorry, we are unable to update your service package at this time!');
    }
  };

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']} textAlign="center">
      <Box.Flex margin="0 0 24px" gap={['4px', 0, '12px']} flexDirection="column">
        <Text.Title size="small" color={COLORS.tealDark}>
          Choose your pickup package
        </Text.Title>
        <Text.Body color={COLORS.storm}>We have you covered whether you want to DIY or sit back and relax!</Text.Body>
      </Box.Flex>
      <Box.Flex
        gap="16px"
        alignItems="center"
        flexDirection={['column', 'row', 'column', 'row']}
        justifyContent="center"
      >
        <Card
          banner="Lowest cost"
          onSelect={() => setSelectedServicePackage(DoorToDoor__BundleEnum.Basic)}
          selected={(selectedServicePackage || currentServicePackage) === DoorToDoor__BundleEnum.Basic}
          subtitle="You do the packing, we pick up your pre-packed boxes and furniture."
          testId="bundle-basic"
          title="Basic"
          valueProps={{
            title: 'Includes',
            values: [
              {
                icon: truck,
                description: 'Truck, dollies, and wrapping materials',
              },
              {
                icon: moversWithTv,
                description: 'Up to 3 full-time trained movers',
              },
              {
                icon: tracking,
                description: 'Real-time GPS tracking',
              },
            ],
          }}
        />
        <Card
          banner="Best value"
          onSelect={() => setSelectedServicePackage(DoorToDoor__BundleEnum.PickupAndPacking)}
          selected={(selectedServicePackage || currentServicePackage) === DoorToDoor__BundleEnum.PickupAndPacking}
          subtitle="We bring boxes and packing materials, and pack everything for you!"
          testId="bundle-pickup_and_packing"
          showBadge
          flatFee={pickupAndPackingBundle.packageSetEntry?.fixedCost ?? undefined}
          title="Pickup & Packing"
          valueProps={{
            title: (
              <>
                Everything in <Text color={COLORS.panther}>Basic</Text> plus
              </>
            ),
            values: [
              {
                icon: tape,
                description: 'Unlimited packing boxes and supplies',
              },
              {
                icon: stackedBoxes,
                description: 'We pack boxes for you',
              },
              {
                icon: moverPackingBoxes,
                description: 'Our best Move & Pack Specialists',
              },
            ],
          }}
        />
      </Box.Flex>
      <Box margin="32px">
        <Button type="submit" className="primary" loading={saving} onClick={() => saveSelection()}>
          Save Selection
        </Button>
      </Box>
      {error && (
        <Box color={COLORS.toucan} margin="16px">
          <p>{error}</p>
        </Box>
      )}
    </Box>
  );
};
